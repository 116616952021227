<template>
  <div class="tb-registration-form">
    <div class="tb-registration-form__controls">
      <div class="tb-registration-form__input-section">
        <TbInput
          v-for="(field, key) in fields"
          ref="input"
          :key="field.type"
          v-model.trim="registrationConfig[key]"
          :icon="field.icon"
          :type="field.type"
          :pattern="field.pattern"
          :inputmode="field.inputMode"
          :password-reveal="field.reveal"
          :autocomplete="field.autocomplete"
          :label="field.label"
          :error-message="field.errorMessage"
          :is-invalid="!validateState[key]"
          :placeholder="field.placeholder"
          @input="onInput({type:key, value: $event.target.value})"
        />
      </div>
      <div class="tb-registration-form__button-container">
        <TbRegistrationButton
          :loading="isLoading"
          :disabled="!isButtonDisabled"
          @click="registrateUser"
        />
      </div>
    </div>
    <TbSmartCaptcha
      id="captcha-container"
      ref="captcha"
      v-model="smartToken"
      :site-key="siteKeyToken"
      :callback="captchaCallback"
    />
  </div>
</template>

<script>
import TbInput from './tb-input.vue';
import TbRegistrationButton from './tb-registration-button.vue';
import registrationApi from '../api/index';
import TbSmartCaptcha from './tb-smart-captcha.vue';

const REQUIRED_QUERY_PARAMS_POOL = [
  'ref',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'sum',
  'paymentPeriod',
  'clientsBase',
  'messengers',
  'fromPage',
];

function getParams() {
  const params = decodeURIComponent(window.location.search.substring(1))
    .split('&')
    .map((pairString) => pairString.split('='))
    .filter(([key, value]) => value && REQUIRED_QUERY_PARAMS_POOL.includes(key));

  return Object.fromEntries(params);
}

export default {
  name: 'TbRegistrationForm',

  components: {
    TbInput,
    TbRegistrationButton,
    TbSmartCaptcha,
  },

  data() {
    return {
      smartToken: '',
      siteKeyToken: process.env.VUE_APP_YANDEX_SMARTCAPTCHA_TOKEN,
      isCaptchaSolved: false,
      accountId: null,
      clientId: null,
      referrer: null,
      baseUrl: process.env.VUE_APP_REDIRECT_URL,
      registrationConfig: {
        email: sessionStorage.getItem('email') || '',
        password: '',
        phone: sessionStorage.getItem('phone') || '',
      },
      isLoading: false,
      fields: {
        email: {
          type: 'email',
          label: 'Email',
          icon: 'email',
          placeholder: 'example@mail.com',
          // eslint-disable-next-line no-useless-escape
          pattern: '^[A-z0-9-_.~+]+@[A-z0-9-_.~]+[.][A-z0-9]+$',
          errorMessage: this.$t('email_validation_error'),
          autocomplete: 'off',
          reveal: false,
          inputMode: 'email',
          ref: 'emailInput',
        },
        phone: {
          type: 'text',
          label: this.$t('phone'),
          icon: 'phone',
          placeholder: '79991234567',
          pattern: '^[+]{0,1}[0-9]{6,18}$',
          errorMessage: this.$t('phone_validation_error'),
          reveal: false,
          autocomplete: 'off',
          inputMode: 'tel',
          ref: 'telInput',
        },
        password: {
          type: 'password',
          label: this.$t('password'),
          icon: 'lock',
          placeholder: '',
          // eslint-disable-next-line no-useless-escape
          pattern: '^[^]{8,}$',
          errorMessage: this.$t('password_validation_error'),
          reveal: true,
          autocomplete: 'new-password',
          inputMode: 'text',
          ref: 'passwordInput',
        },
      },
      validateState: {
        email: true,
        phone: true,
        password: true,
      },
    };
  },
  computed: {
    isPasswordValid() {
      return new RegExp(this.fields.password.pattern).test(this.registrationConfig.password);
    },
    isAllInputsValid() {
      return !(new RegExp(this.fields.phone.pattern).test(this.registrationConfig.phone)
        && new RegExp(this.fields.email.pattern).test(this.registrationConfig.email)
        && new RegExp(this.fields.password.pattern).test(this.registrationConfig.password));
    },
    isButtonDisabled() {
      return this.isPasswordValid && this.registrationConfig.phone && this.registrationConfig.email;
    },
  },
  watch: {
    'registrationConfig.password': {
      handler(value) {
        this.validateState.password = value ? new RegExp(this.fields.password.pattern).test(value) : true;
      },
    },
    'registrationConfig.email': {
      handler(value) {
        sessionStorage.setItem('email', value);
      },
    },
    'registrationConfig.phone': {
      handler(value) {
        sessionStorage.setItem('phone', value);
      },
    },
  },

  methods: {
    captchaCallback(token) {
      this.smartToken = token;
      this.isCaptchaSolved = true;
      this.submitRegistrationForm();
    },

    validateInputs() {
      Object.entries(this.registrationConfig).forEach(([key, value]) => {
        this.validateState[key] = new RegExp(this.fields[key].pattern).test(value);
      });
    },

    async registrateUser() {
      if (this.isAllInputsValid) {
        this.validateInputs();
        this.fields.email.errorMessage = this.$t('email_validation_error');
        return;
      }

      this.smartToken = '';
      this.isCaptchaSolved = false;

      if (!this.isCaptchaSolved) {
        if (window.smartCaptcha) {
          window.smartCaptcha.reset();
          window.smartCaptcha.execute();
        }
      } else {
        this.submitRegistrationForm();
      }
    },

    async submitRegistrationForm() {
      const data = {
        owner: this.registrationConfig,
        options: this.getRegistrationOptions(),
        smartToken: this.smartToken,
      };

      try {
        this.isLoading = true;

        const { loginToken, accountId } = await registrationApi.postRegistration(data);

        window.ym(37829670, 'reachGoal', 'leadtrial');

        window.dataLayer.push({ event: 'registration' });

        this.submitGA(accountId);
        window.ym(37829670, 'reachGoal', 'leadtrial');

        window.location.href = `${this.baseUrl}?loginToken=${loginToken}`;
      } catch (error) {
        if (error.data.detail) {
          error.data.detail.forEach((err) => {
            if (err.msg.includes('Invalid phone number')) {
              this.$buefy.notification.open({
                message: this.$t('invalid_phone'),
                type: 'is-dark',
                duration: 3000,
                hasIcon: true,
                icon: 'alert-circle',
                position: 'is-top-right',
                role: 'alert',
              });
              this.validateState.phone = false;
            }

            if (err.msg === 'value is not a valid email address') {
              this.$buefy.notification.open({
                message: this.$t('invalid_email'),
                type: 'is-dark',
                duration: 3000,
                hasIcon: true,
                icon: 'alert-circle',
                position: 'is-top-right',
                role: 'alert',
              });
              this.validateState.email = false;
            }
          });
        } else if (error.data.$error.scn.includes('UNIQUE_CONSTRAINT_FAILED')) {
          this.$buefy.notification.open({
            message: this.$t('registered_email'),
            type: 'is-dark',
            duration: 3000,
            hasIcon: true,
            icon: 'alert-circle',
            position: 'is-top-right',
            role: 'alert',
          });
          this.fields.email.errorMessage = this.$t('registered_email_error_message');
          this.validateState.email = false;
        } else {
          this.$buefy.notification.open({
            message: this.$t('registration_error_message'),
            type: 'is-dark',
            duration: 3000,
            hasIcon: true,
            icon: 'alert-circle',
            position: 'is-top-right',
            role: 'alert',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },

    getRegistrationOptions() {
      return {
        params: getParams(),
        lang: this.$i18n.locale,
      };
    },

    submitGA(accountId) {
      this.accountId = accountId;
      window.ga('create', 'UA-70617315-1', { alwaysSendReferrer: true });
      window.ga((tracker) => {
        this.clientId = tracker.get('clientId');
        this.referrer = sessionStorage.getItem('referrer') ? sessionStorage.getItem('referrer') : tracker.get('referrer');
      });

      if (sessionStorage.getItem('referrer')) {
        sessionStorage.removeItem('referrer');
      }

      window.ga('send', 'event', 'Registration', 'Confirm', {
        dimension1: this.accountId,
        dimension2: this.clientId,
        dimension3: this.referrer,
      });
    },

    onInput({ type, value }) {
      this.validateState[type] = true;

      if (type === 'phone') {
        const regexp = new RegExp(/[А-яA-z]/);

        this.registrationConfig[type] = value.trim()
          .split('')
          .map((item) => item.replace(regexp, ''))
          .join('');

        return;
      }
      this.registrationConfig[type] = value.trim();
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.tb-registration-form {
  width: 100%;

  &__input-section {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__controls {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__input-label {
    color: #fff !important;
  }

  &__input-field {
    color: #fff;

  }

  &__input {
    width: 100%;
  }

  &__button-container {
    display: grid;
  }

  &__input-phone-wrapper {
    display: block;
    height: max-content;
    box-shadow: 0 0 0 1px #D0D0D0;
    border-radius: 8px;

    &_focused {
      box-shadow: 0 0 0 1px rgba(115, 95, 245, 1);
    }

    &_invalid {
      box-shadow: 0 0 0 1px $danger;
    }

  }

}

@media screen and (max-width: 1919px) {
  .tb-registration-form {
    &__input-section {
      row-gap: 28px;
    }

    &__input-phone-wrapper {
      height: 48px;
      background-color: #fff;
    }

    &__controls {
      row-gap: 28px;
    }
  }
}

@media screen and (max-width: 500px) {
  .tb-registration-form {
    &__input-section {
      row-gap: 28px;
    }

    &__controls {
      row-gap: 28px;
    }

    &__input-phone-wrapper {
      height: 43px;
      background-color: #fff;
      outline: none !important;
      box-shadow: 0 0 0 2px #D0D0D0 !important;

      &_focused {
        outline: none !important;
        box-shadow: 0 0 0 2px rgba(115, 95, 245, 1) !important;
      }

      &_invalid {
        outline: none !important;
        box-shadow: 0 0 0 2px #FF8081 !important;
      }
    }

  }
}

</style>

<i18n>
{
  "ru": {
    "email_validation_error": "Неверный формат электронной почты",
    "phone_validation_error": "Неверный формат номера телефона",
    "password_validation_error": "Минимум 8 символов",
    "min_8_symbols": "Минимум 8 символов",
    "registration_error_message": "При регистрации произошла ошибка, перезагрузите страницу и попробуйте снова",
    "email": "Почта",
    "phone": "Номер телефона",
    "password": "Пароль",
    "registrate": "Зарегистрироваться",
    "registered_email": "Электронная почта уже привязана к другому аккаунту",
    "registered_email_error_message": "Эта электронная почта уже используется",
    "example_phone": "Пример :",
    "phone_number_label": "Номер телефона",
    "country_code": "Код страны",
    "invalid_phone": "Неверный номер телефона. Проверьте введенные данные и попробуйте еще раз.",
    "invalid_email": "Неверный email. Проверьте введенные данные и попробуйте еще раз."
  },
  "en": {
    "email_validation_error": "Invalid email format",
    "phone_validation_error": "Invalid phone number format",
    "password_validation_error": "Minimum 8 characters",
    "min_8_symbols": "At least 8 symbols",
    "registration_error_message": "An error occurred during registration, please reload the page and try again",
    "registered_email_error_message": "This email address is already in use",
    "email": "Email",
    "phone": "Phone number",
    "password": "Password",
    "registrate": "Register",
    "registered_email": "Email is already linked to another account",
    "example_phone": "Example :",
    "phone_number_label": "Phone number",
    "country_code": "Country code",
    "invalid_phone": "The phone number is invalid. Check the data and try again.",
    "invalid_email": "The email in invalid. Check the data and try again."
  }
}
</i18n>
