<template>
  <div
    class="tb-input__wrapper"
    :class="inputWrapperStateClass"

    @click="focusInput"
  >
    <div
      class="control-container"
      :class="wrapperClass"
    >
      <div class="tb-input__icon-wrapper">
        <BIcon
          class="tb-input__icon"
          :icon="icon"
        />
      </div>
      <div
        class="tb-input__input-wrapper"
      >
        <span
          v-if="isCaps && type === 'password'"
          class="tb-input__caps-info-box"
        >
          {{ $t('caps') }}
        </span>
        <input
          ref="TbInput"
          class="tb-input__input"
          name="TbInput"
          :placeholder="inputPlaceholderText"
          :type="inputType"
          v-bind="$attrs"
          v-on="$listeners"
          @focus="handleFocus"
          @blur="handleBlur"
        >
        <div
          class="tb-input__label"
          :class="labelClass"
        >
          {{ label }}
        </div>
      </div>
      <div
        v-if="passwordReveal && type === 'password'"
        class="tb-input__reveal-button"
        role="button"
        @click="revealPassword"
      >
        <BIcon :icon="revealIcon" />
      </div>
    </div>
    <div
      v-if="isInvalid"
      class="tb-input__error-message"
    >
      <span> {{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TbInput',

  props: {
    type: {
      type: String,
      default: 'text',
    },

    icon: {
      type: String,
      default: '',
    },

    passwordReveal: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      inputType: this.type,
      focused: false,
      isCaps: false,
    };
  },

  computed: {
    wrapperClass() {
      return this.passwordReveal ? 'tb-input__wrapper_with-reveal' : 'tb-input__wrapper_default';
    },
    inputWrapperStateClass() {
      if (this.isInvalid) {
        return 'tb-input__wrapper_invalid';
      }
      if (this.focused) {
        return 'tb-input__wrapper_focused';
      }
      return '';
    },
    inputPlaceholderText() {
      return this.focused ? this.placeholder : '';
    },

    labelClass() {
      return this.focused ? 'tb-input__label_focus' : 'tb-input__label_unfocus';
    },

    revealIcon() {
      return this.inputType === 'password' ? 'eye' : 'eye-off';
    },
    modelModifier() {
      if (this.type === 'tel') {
        return 'number';
      }
      return 'trim';
    },

  },
  watch: {
    '$attrs.value': {
      handler(value) {
        if (value) {
          this.focused = true;
        }
      },
      immediate: true,
    },
  },

  mounted() {
    document.addEventListener('keydown', this.capsLockDetect);
    document.addEventListener('keypress', this.capsLockDetect);
  },

  methods: {
    handleFocus() {
      this.focused = true;
    },
    handleBlur({ target }) {
      if (target.value) return;
      this.focused = false;
    },

    focusInput() {
      this.$refs.TbInput.focus();
    },

    revealPassword() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
        return;
      }

      this.inputType = 'password';
    },
    capsLockDetect(e) {
      if (!e) {
        this.isCaps = false;
        return;
      }

      const n = e.keyCode ? e.keyCode : e.charCode;

      if (e.type === 'keypress') {
        const c = String.fromCharCode(n);
        const cUC = c.toUpperCase();
        const cLC = c.toLowerCase();

        if (cUC !== cLC) {
          this.isCaps = (e.shiftKey && cLC === c) || (!e.shiftKey && cUC === c);
        }
      } else if (e.type === 'keydown' && n === 20) {
        this.isCaps = false;
      }
    },
  },

};
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/_variables.scss';

.tb-input {
  &__tb-input__icon {
    height: max-content !important;
  }

  &__input {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
    background-color: transparent !important;
    width: 100%;

  }

  &__caps-info-box {
    position: absolute;
    transition: font-size .2s, top .2s;
    font-size: 12px;
    font-weight: 600;
    height: 8px;
    top: 2%;
    right: 0;
    color: $primary;
  }

  &__wrapper {
    width: 100%;

    height: 55px;
    background: #fff;
    display: grid;
    border-radius: 8px;
    padding: 0 5px;
    z-index: 0;
    cursor: pointer;
    border: none !important;
    box-shadow: 0 0 0 1px #D0D0D0;

    &:hover {
      background: rgba(208, 208, 208, 0.15);
      box-shadow: 0 0 0 1px rgba(208, 208, 208, 1);
    }

    &_invalid {
      box-shadow: 0 0 0 1px $danger;

      &:hover {
        background: rgba(208, 208, 208, 0.15);
        box-shadow: 0 0 0 1px $danger;
      }
    }

    &_focused {
      box-shadow: 0 0 0 1px $primary;

      &:hover {
        background: rgba(208, 208, 208, 0.15);
        box-shadow: 0 0 0 1px $primary;
      }
    }

    &_with-reveal {
      grid-template-columns: 40px 1fr 40px;
    }

    &_default {
      grid-template-columns: 40px 1fr;
    }
  }

  &__input-wrapper {
    display: block;
    width: 100%;
    position: relative;
  }

  &__icon-wrapper {
    color: #dbdbdb;
    padding: 0 5px 0 3px;
    z-index: 10;
  }

  &__label {
    position: absolute;
    transition: font-size .2s, top .2s;

    &_focus {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 10px;
      top: 3%;
      height: 8px;
      color: $primary;
    }

    &_unfocus {
      font-size: 15px;
      top: 25%;
      color: #D0D0D0;
    }
  }

  &__reveal-button {
    cursor: pointer;
    justify-self: center;
    display: flex;
    align-items: center;
    z-index: 0;
    color: $primary;
  }

  &__error-message {
    color: $danger;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
    margin-top: 1px;
  }
}

.control-container {
  width: 100%;
  height: 55px;
  display: grid;
  align-items: center;
}

input {
  border: none;
  border-radius: 8px;
  background-color: transparent !important;
  padding: 18px 0 0 0;
  font-size: 1rem;
  z-index: 2 !important;
}

input:focus-visible {
  outline: none;
}

input:focus {
  outline: none;
}

input:active {
  outline: none;
}

input::placeholder {
  color: #D0D0D0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

@media screen and (max-width: 1919px) {
  input {
    padding-top: 16px;
  }

  .control-container {
    height: 46px;
  }

  .tb-input {
    &__wrapper {
      height: 46px;
    }

    &__error-message {
      margin-top: -1px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 500px) {
  .tb-input {
    &__label {
      &_focus {
        font-size: 12px;
        height: 10px;
        top: 10%;
      }

      &_unfocus {
        font-size: 15px;
        top: 20%;
        color: #D0D0D0;;
      }
    }

    &__caps-info-box {
      font-size: 12px;
      height: 10px;
      color: #686868;
      top: 10%;
    }

    &__wrapper {
      height: 43px;
      outline: none;

      box-shadow: 0 0 0 2px #D0D0D0;

      &:hover {
        background: #fff;
        outline: none;
      }

      &_invalid {
        outline: none;
        box-shadow: 0 0 0 2px #FF8081;

        &:hover {
          background: #fff;
          outline: none;
          box-shadow: 0 0 0 2px #FF8081;
        }
      }

      &_focused {
        outline: none;
        box-shadow: 0 0 0 2px $primary;

        &:hover {
          background: #fff;
          outline: none;
        }
      }
    }

    &__error-message {
      color: #FF8081;
      margin-top: -1%;
    }
  }
}

</style>

<i18n>
{
  "ru": {
    "caps": "CAPS LOCK"
  },
  "en": {
    "caps": "CAPS LOCK"
  }
}
</i18n>
