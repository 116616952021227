/* eslint-disable vue/no-v-html */
<template>
  <div class="registration">
    <h3 class="title registration__title">
      {{ $t('signup') }}
    </h3>
    <TbRegistrationForm />

    <div class="registration__sign-in">
      <p
        class="registration__sign-in-offer"
      >
        {{ $t('offer_1') }}
        <a
          class="registration__sign-in-link"
          target="_blank"
          href="https://textback.ru/oferta/"
        >
          {{ $t('offer_2') }}
        </a>

        <a
          class="registration__sign-in-link"
          target="_blank"
          href="https://textback.ru/privacy-policy/"
        >
          {{ $t('offer_3') }}
        </a>
        {{ $t('offer_4') }}
      </p>
      <p class="registration__sign-in-container">
        <span class="registration__sing-in-button">

          <a
            :href="baseUrl"
          >
            <span class="registration__sing-in-button">{{ $t('sign_in') }}</span>
            <span class="registration__sing-in-button enter"> {{ $t('enter') }}</span>
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>

import TbRegistrationForm from './tb-registration-form.vue';

export default {
  name: 'TbRegistration',

  components: {
    TbRegistrationForm,

  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_REDIRECT_URL,
    };
  },

};
</script>

<style lang="scss" scoped>
.registration {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  align-items: center;
  padding-bottom: 20px;

  &__title {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
    color: #454545;
  }

  &__sign-in {
    display: grid;
    row-gap: 20px;
    justify-items: left;
    margin-top: 16px;
  }

  &__sign-in-link {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #735FF5;
  }

  &__sing-in-button {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #735FF5;
  }

  &__sign-in-container {
    display: grid;
    justify-items: flex-start;
    color: #fff;
    width: 100%;
    grid-template-columns: 1fr;
    column-gap: 5px;
  }

  &__sign-in-offer {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    color: #686868;
  }
}

@media screen and (max-width: 1024px) {
  .registration {
    &__title {
      font-size: 24px;
      height: 36px;
      margin-bottom: 24px;
    }

    &__sign-in {
      margin-top: 12px;
      row-gap: 28px;
      font-size: 12px;
    }

    &__sign-in-offer {
      font-size: 12px;
    }

    &__sign-in-link {
      font-size: 12px;
    }

    &__sing-in-button {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .registration {
    &__title {
      display: none;
    }

    &__sign-in {
      margin-top: 12px;
      row-gap: 28px;
      font-size: 12px;
    }

    &__sign-in-offer {
      font-size: 12px;
    }

    &__sign-in-link {
      font-size: 12px;
    }

    &__sing-in-button {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 500px) {
  .enter {
    font-weight: bold;
  }
  .registration {
    grid-template-columns: 100%;
    justify-items: center;

    &__sign-in {
      margin-top: 12px;
      row-gap: 28px;
      font-size: 12px;
      text-align: center;
    }

    &__sign-in-offer {
      font-size: 12px;
      color: #fff;
    }

    &__sign-in-link {
      font-size: 12px;
      text-decoration: underline;
      color: #fff;
    }

    &__sing-in-button {
      font-size: 12px;
      color: #fff;
      text-align: center;
    }

    &__sign-in-container {
      justify-items: center;
    }
  }
}

</style>

<i18n>
{
  "ru": {
    "sign_in": "Уже есть аккаунт?",
    "enter": "Войти",
    "offer_1": "Нажимая 'Регистрация', вы соглашаетесь с условиями",
    "offer_2": "лицензионного договора,",
    "offer_3": "политикой конфиденциальности",
    "offer_4": "и предоставляете согласие на обработку персональных данных.",
    "signup": "Регистрация"
  },
  "en": {
    "sign_in": "Already using TextBack?",
    "enter": "Sign in",
    "offer_1": "By clicking 'Start free trial', you agree to the terms of",
    "offer_2": "the license agreement,",
    "offer_3": "privacy policy",
    "offer_4": "and consent to the processing of personal data.",
    "signup": "Sign Up"
  }
}
</i18n>
