<script>
export default {
  props: {
    siteKey: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: "'ru' | 'en' | 'be' | 'kk' | 'tt' | 'uk' | 'uz' | 'tr'",
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      widgetId: 'captcha-container',
    };
  },
  mounted() {
    this.loadCaptchaScript();
  },
  beforeDestroy() {
    const captchaElement = document.getElementById(this.widgetId);
    if (captchaElement) {
      captchaElement.innerHTML = '';
    }
    const script = document.getElementById('captcha-container');
    if (script) {
      script.remove();
    }
  },
  methods: {
    loadCaptchaScript() {
      if (!document.getElementById('yandex-captcha-script')) {
        const script = document.createElement('script');
        script.id = 'yandex-captcha-script';
        script.src = 'https://smartcaptcha.yandexcloud.net/captcha.js';
        script.defer = true;
        script.onload = this.renderCaptcha;
        document.head.appendChild(script);
      } else {
        this.renderCaptcha();
      }
    },
    renderCaptcha() {
      if (!window.smartCaptcha) {
        return;
      }

      window.smartCaptcha.render(this.widgetId, {
        sitekey: this.siteKey,
        shieldPosition: 'bottom-right',
        invisible: true,
        test: false,
        hl: this.lang,
        callback: (token) => {
          this.$emit('input', token);
          this.callback(token);
        },
      });
    },
  },
  render(h) {
    return h('div', {
      attrs: {
        id: this.widgetId,
        class: 'captcha-container',
      },
    });
  },
};
</script>
