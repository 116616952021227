<template>
  <button
    class="registration-button"
    :disabled="disabled"
    :class="[disabled ? 'registration-button_disabled' : 'registration-button_active']"
    v-on="$listeners"
  >
    <b-loading
      v-model="loading"
      :is-full-page="false"
    />
    <span
      v-if="!loading"
      class="registration-button__text"
    >{{ $t('registration') }}</span>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-button {
  border: none;
  height: 55px;
  border-radius: 8px;
  cursor: pointer;
  color: #FFFFFF;
  position: relative;

  &_active {
    background: #735FF5;

    &:hover {
      background: #533FDB;
      outline: none;
      box-shadow: none;
    }

    &:active {
      background: #594BB7;
      outline: none;
      box-shadow: none;
    }
  }

  &_disabled {
    cursor: not-allowed;
    background: #D0D0D0;
  }

  &__text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }
}

@media screen and (max-width: 1919px) {
  .registration-button {
    height: 46px;

    &__text {
      font-size: 16px;
    }
  }

}

@media screen and (max-width: 1000px) {
  .registration-button {
    &_active {
      background: #333333;

      &:hover {
        background: #333333;
      }

      &:active {
        background: rgba(101, 101, 101, 1);
        color: rgba(239, 239, 239, 1);
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .registration-button {
    &_active {
      background: #333;

      &:hover {
        background: #333;
      }

      &:active {
        background: #656565;
      }
    }
  }
}

</style>
<i18n>
{
  "ru": {
    "registration": "Зарегистрироваться"
  },
  "en": {
    "registration": "Start free trial"
  }
}
</i18n>
