<template>
  <b-button
    class="tb-locale-changer__button"
    @click="changeLocale(selectedLocale.switchTo)"
  >
    <BImage
      class="tb-locale-changer__image"
      :src="selectedLocale.icon"
    />
  </b-button>
</template>

<script>

import ruIcon from '../assets/img/ru_flag.svg';
import enIcon from '../assets/img/en_flag.svg';

export default {
  name: 'TbLocaleChanger',

  data() {
    return {
      locale: this.$i18n.locale,
      availableLocales: {
        ru: {
          value: 'ru', label: 'Русский', icon: ruIcon, switchTo: 'en',
        },
        en: {
          value: 'en', label: 'English', icon: enIcon, switchTo: 'ru',
        },
      },
    };
  },

  computed: {
    selectedLocale() {
      return this.availableLocales[this.$i18n.locale];
    },
  },

  watch: {
    locale(val) {
      localStorage.setItem('locale', val);
      document.location.reload();
    },
  },

  methods: {
    async changeLocale(lang) {
      this.locale = lang;
    },
  },

};
</script>

<style lang="scss">
.tb-locale-changer {

  &__button {
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 53px;
    height: 53px;

    &:active {
      background-color: transparent !important;
      outline: none !important;
    }

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__image {
    width: 53px;
    height: 53px;
    background-color: transparent;
  }

}

@media screen and (max-width: 1919px) {
  .tb-locale-changer {
    &__image {
      width: 33px;
      height: 33px;
    }

    &__button {
      width: 33px;
      height: 33px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tb-locale-changer {
    &__image {
      width: 33px;
      height: 33px;
    }
  }
}

@media screen and (max-width: 500px) {
  .tb-locale-changer {
    &__image {
      width: 24px;
      height: 24px;
    }
  }
}

</style>
