<template>
  <div class="main">
    <div class="header-logo header-logo_white">
      <a
        href="https://textback.ru"
        target="_blank"
      >
        <img
          class="header-logo__image"
          src="../assets/img/logo_white.svg"
          alt="tb-logo"
        >
      </a>
    </div>
    <div class="header-logo header-logo_black">
      <a
        href="https://textback.ru"
        target="_blank"
      >
        <img
          class="header-logo__image"
          src="../assets/img/logo_back.svg"
          alt="tb-logo"
        >
      </a>
    </div>
    <div class="title-container">
      <div class="title-container__content">
        <h1 class="title-container__content-head title-container__content-head_desktop">
          {{ $t('text_1') }}
        </h1>
        <h1 class="title-container__content-head title-container__content-head_mobile">
          {{ $t('text_3') }}
        </h1>
        <h5 class="title-container__content-description">
          {{ $t('text_2') }}
        </h5>
        <div class="title-container__logos-container">
          <img
            class="title-container__logo"
            src="../assets/img/whatsapp.svg"
            alt="whatsapp_icon"
          >
          <img
            class="title-container__logo"
            src="../assets/img/vk.svg"
            alt="vk_icon"
          >
          <img
            class="title-container__logo"
            src="../assets/img/messenger.svg"
            alt="messenger_icon"
          >
          <img
            class="title-container__logo"
            src="../assets/img/viber.svg"
            alt="viber_icon"
          >
          <img
            class="title-container__logo"
            src="../assets/img/telegram.svg"
            alt="tg_icon"
          >
          <img
            class="title-container__logo"
            src="../assets/img/instagram.svg"
            alt="instagram_icon"
          >
        </div>
      </div>
    </div>
    <div class="form">
      <div class="form__container">
        <TbRegistration />
      </div>
    </div>
    <TbLocaleChanger class="locale-changer" />
  </div>
</template>

<script>

import TbRegistration from '../components/tb-registration.vue';
import TbLocaleChanger from '../components/tb-locale-changer.vue';

export default {
  name: 'Home',
  components: {
    TbRegistration,
    TbLocaleChanger,
  },
};
</script>

<style lang="scss">
.locale-changer {
  position: absolute;
  top: 80px;
  right: 20px;
}

.main {
  display: grid;
  grid-template-columns: 40% 1fr;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background: url("../assets/img/pattern.svg") repeat;
}

.title-container {
  display: grid;
  grid-template-columns: 490px;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;

  &__content {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__content-head {
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 49px;
    letter-spacing: 0;
    text-align: left;
    color: #fff;

    &_mobile {
      display: none;
    }
  }

  &__content-description {
    font-weight: 400;
    width: 450px;
    font-size: 24px;
    text-align: left;
    line-height: 130%;
    color: rgba(255, 255, 255, 1);
    margin-top: 16px;
  }

  &__logos-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
    margin-top: 30px;;
  }

  &__logo {
    width: 65px;
    height: 65px;
  }
}

.header-logo {
  width: max-content;
  height: max-content;
  display: flex;
  position: absolute;
  flex-direction: row;
  z-index: 5;

  &_white {
    padding: 20px 20px 0 0;
    display: block;
    top: 0;
    right: 0;
    justify-content: right;
  }

  &_black {
    padding: 40px 0 0 62px;
    display: none;
    top: 0;
    left: 0;
    justify-content: left;
  }

  &__image {
    width: 158px;
    height: 55px;
  }
}

.form {
  background-color: #fff;
  height: 100%;

  &__container {
    display: flex;
    width: 448px;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
  }
}

@media screen and (max-width: 1700px) {
  .title-container {
    grid-template-columns: 500px;

    &__content-head {
      font-size: 30px;
      line-height: 130%;
      width: 100%;
    }

    &__content-description {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .locale-changer {
    top: 80px;
    right: 20px;
  }
  .main {
    grid-template-columns: 536px 1fr;
  }

  .header-logo {
    &__image {
      width: 158px;
      height: 55px;
    }
  }
  .title-container {
    grid-template-columns: 450px;
    padding-top: 20vh;

    &__content-head {
      font-size: 26px;
    }

    &__logo {
      margin: 0;
      width: 50px;
      height: 50px;
    }
  }

  .form {
    &__container {
      width: 352px;
      padding-top: 20vh;
    }
  }

}

@media screen and (max-width: 1024px) {
  .locale-changer {
    top: auto;
    bottom: 60px;
    right: 40px;
  }
  .main {
    grid-template-columns: 1fr 1fr;
    column-gap: 36px;
    padding: 0 44px;
    align-items: center;
  }

  .header-logo {

    &_black {
      display: block;
    }

    &_white {
      display: none;
    }

    &__image {
      width: 187px;
      height: 65px;
    }
  }
  .title-container {
    padding: 0;
    width: 100%;
    justify-content: center;
    grid-template-columns: 430px;

    &__content-head {
      font-size: 36px;
      line-height: 130%;
      width: 100%;
    }

    &__content-description {
      font-size: 20px;
      line-height: 130%;
      margin-top: 8px;
      width: 100%;
    }

    &__logos-container {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-top: 30px;
      width: 380px;
    }

  }
  .form {
    height: 616px;
    width: 470px;
    align-self: center;
    padding: 92px 80px;
    border-radius: 24px;

    &__container {
      padding: 0;
      margin: 0 auto;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .main {
    padding: 120px 16px 106px 16px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: flex-start;
    row-gap: 20px;
  }
  .form {
    height: max-content;

    &__container {
      height: 100%;
    }
  }

  .title-container {
    justify-items: center;
    grid-template-columns: 100%;

    &__content {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      text-align: center;
      width: max-content;
    }

    &__content-head {
      width: 500px;
      font-size: 36px;
      line-height: 130%;
      text-align: center;

      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    &__content-description {
      font-size: 20px;
      line-height: 130%;
      margin-top: 8px;
      width: max-content;
    }

    &__logos-container {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-top: 20px;
      width: max-content;
    }
  }
}

@media screen and (max-width: 500px) {
  .locale-changer {
    top: 16px;
    right: 16px;
  }

  .main {
    padding: 75px 5% 0 5%;
    justify-content: start;
    background: url("../assets/img/pattern-mobile.svg") repeat;
  }

  .header-logo {
    width: 187px;
    height: 65px;
    position: absolute;
    flex-direction: row;
    z-index: 5;

    &__image {
      width: 88px;
      height: 31px;
    }

    &_black {
      padding: 16px 0 0 16px;
    }
  }

  .title-container {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    width: 100%;

    &__content {
      width: 100%;
      text-align: center;
      padding: 0 25px;
    }

    &__content-head {
      font-size: 20px;
      width: 100%;
      text-align: center;

    }

    &__content-description {
      font-size: 15px;
      line-height: 130%;
      margin-top: 8px;
      font-weight: normal;
      text-align: center;
      width: 100%;
    }

    &__logos-container {
      display: none;
    }
  }

  .form {
    padding: 0;
    height: max-content;
    width: 100%;
    background-color: transparent;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: center;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-height: 650px) and (max-width: 500px) {
  .main {
    padding: 75px 5% 100px 5%;
  }
}

</style>

<i18n>
{
  "ru": {
    "text_1": "Помогаем автоматизировать маркетинг с помощью рассылок и чат-ботов",
    "text_2": "Пользуйтесь бесплатно 14 дней",
    "text_3": "Регистрация"
  },
  "en": {
    "text_1": "Automate your marketing with broadcasts and chatbots",
    "text_2": "Start free for 14 days",
    "text_3": "Create your account"
  }
}
</i18n>
